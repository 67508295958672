export const data = {
  2023: {
    heading: 'The 2023 Charitable Impact Awards winners are',
    subheading: 'Wealth Advisor Team of the Year',
    block: {
      title: 'PWL Capital team at PWL',
      subTitle: 'Ben Wilson, Jaclyn Cecereu and Matt Gour',
      description:
        'The PWL Capital team won this award because of their outstanding engagement with charitable giving on behalf of their clients, helping them give more cost-effectively and meaningfully while providing another meaningful purpose for their wealth. Using the Charitable Investment Program, the PWL Capital team opened accounts for clients who donated over $20 million while supporting clients to make almost $7 million in grants throughout 2022.',
      image: '/awards/PWL-Capital-team.png',
      logo: '/awards/PWL-Capital-logo.png',
    },
    advisorTeam: [
      {
        thumbnail: '/awards/2023-team-1.png',
        title: 'Alan Tynan',
        description: 'RBC Wealth Management and PH&N Investment Counsel',
        logo: '/awards/2023-team-1-logo.png',
      },
      {
        thumbnail: '/awards/2023-team-2.png',
        title: 'Shay Keil',
        description: 'Scotia Wealth Management',
        logo: '/awards/2023-team-2-logo.png',
      },
      {
        thumbnail: '/awards/2023-team-3.png',
        title: 'Doug Nelson',
        description: 'Nelson Portfolio Management',
        logo: '/awards/2023-team-3-logo.png',
      },
      {
        thumbnail: '/awards/2023-team-4.png',
        title: 'Christopher Motion',
        description: 'RBC Dominion Securities',
        logo: '/awards/2023-team-4-logo.png',
      },
      {
        thumbnail: '/awards/2023-team-5.png',
        title: 'Gord Love',
        description: 'Wellington-Altus Private Wealth',
        logo: '/awards/2023-team-5-logo.png',
      },
    ],
    quotes: [
      {
        quote:
          'The PWL Capital team is motivated to help our clients live good, fulfilling lives. Fortunately, giving back often becomes an important part of what a fulfilled life means to them. Our journey with philanthropy is a reflection of the generous clients we work with, and the charitable goals and ambitions they have. We are grateful to be a part of it.',
        image: '/awards/Jaclyn-Cecereau.png',
        name: 'Jaclyn Cecereu',
        title: 'PWL Capital team at PWL',
      },
      {
        quote:
          'What an absolute honor to be recognized by Charitable Impact.  This award recognizes the philanthropic nature of our wonderful clients.  Combining the market leading innovations of Charitable Impact and the mindfulness of our clients creates a synergy that provides a significant benefit to our communities.',
        image: '/awards/Alan-Tynan.png',
        name: 'Alan Tynan',
        title: 'RBC Wealth Management and PH&N Investment Counsel',
      },
      {
        quote:
          'I am passionate about giving back and I am so fortunate to work with so many clients who also want to make impactful gifts to our greater community.  Charitable Impact is an amazing partner and I am thrilled to work closely with them in advancing all forms of charitable giving and building a foundation for life-long community support. It is truly a win-win for our clients and our community.',
        image: '/awards/Shay-Keil.png',
        name: 'Shay Keil',
        title: 'Scotia Wealth Management',
      },
      {
        quote:
          'Charitable giving is an extremely important part of the overall planning process.  It provides a positive tax-effective outcome to current income, it can make a substantial difference in the client’s final estate, and it also helps bring families together with a focus on a common set of values and preferences.  Charitable giving strategies help families, on a multi-generational basis, see the world as a place much bigger than just themselves and their own local communities.  We love doing this important work together with our clients.',
        image: '/awards/Doug-Nelson.png',
        name: 'Doug Nelson',
        title: 'Nelson Portfolio Management',
      },
    ],
  },

  // Data of 2022
  2022: {
    heading: 'The 2022 Charitable Impact Awards winners are',
    subheading: 'Wealth Advisor Team of the Year',
    block: {
      title: 'Stenner Wealth Partners+',
      description:
        'Stenner Wealth Partners+ of Canaccord Genuity Wealth Management won this award because they facilitated nearly 100 unique donations, the highest number for this period, and generated the most amount of donations contributing over $80 million on gifts ranging from several thousand dollars to many millions of dollars.',
      image: '/awards/Stenner-Wealth.png',
      logo: '/awards/partners-logo.png',
    },
    advisorTeam: [
      {
        thumbnail: '/awards/team-1.png',
        title: 'The Wong Group at Wellington-Altus Private Wealth',
        description: '',
        logo: '/awards/wong-group-logo.png',
      },
      {
        thumbnail: '/awards/team-2.png',
        title: 'PWL Capital Team at PWL',
        description: '',
        logo: '/awards/PWL-logo.png',
      },
      {
        thumbnail: '/awards/team-3.png',
        title: 'Christina Anthony’s team at Odlum Brown',
        description: '',
        logo: '/awards/odlum-brown-logo.png',
      },
      {
        thumbnail: '/awards/team-4.png',
        title: 'Harp Dhillon Group at RBC Dominion Securities Inc.',
        description: '',
        logo: '/awards/RBC-logo.png',
      },
      {
        thumbnail: '/awards/team-5.png',
        title: 'Stephen Webb’s team at Entrust Financial Services',
        description: '',
        logo: '',
      },
    ],
    quotes: [
      {
        quote:
          'We are thrilled. This is an award for all our loyal clients who put their trust in us, and who have supported us. We have constantly tried to innovate in our approach on advising philanthropic endeavours with our clients. This award is a testament to that innovative mindset. My team and I are very honoured personally, and I want to give my massive thanks to Charitable Impact for this award.',
        image: '/awards/Stenner-Wealth-Round.png',
        name: 'Thane Stenner',
        title: 'Stenner Wealth Partners of CG Wealth Management',
      },
      {
        quote:
          'We are honoured to be recognized for the work we do to empower our clients with tools to magnify their charitable impact, and look forward to helping more Canadians give in a smart way, one client at time.',
        image: '/awards/Maili-Wong-Round.png',
        name: 'Maili Wong',
        title: 'Wong Group',
      },
      {
        quote:
          'Working with clients on giving back has always been a valued part of our roles at PWL. Charitable Impact makes this an easy task by echoing our values while supporting our team and clients. We’re able to provide exceptional service and in turn fulfil our clients’ charitable endeavors.',
        image: '/awards/PWL-Round.png',
        name: 'PWL Capital Team',
        title: 'PWL Capital',
      },
      {
        quote:
          'Charitable Impact allows our clients to easily fulfill their dedication to supporting those in need, while achieving their philanthropic goals and creating a legacy. We are proud to play a small role in this – recognizing their goals, creating a plan and maximizing their impact.',
        image: '/awards/Christina-Anthony-Round.png',
        name: 'Christina Anthony',
        title: 'Odlum Brown',
      },
    ],
  },
};

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import {
  StyledH1,
  StyledPara,
} from "../AccountAgreement/AgreementTermsSection";
import ButtonWithAnchor from "../Buttons/ButtonWithAnchor";
import { SectionTitle } from "./AwardWinnersSection";

const SectionWrap = styled.section`
  background: #90459a;
  text-align: center;
  position: relative;
  z-index: 1;
  box-shadow: 0px 2px 19px 0px rgba(0, 0, 0, 0.11);

  &::before,
  &::after {
    content: "";
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: -1;
  }

  &::before {
    background-image: url("/awards/shape-9.png");
    width: 165px;
    height: 175px;
    top: 0;
    left: 0;

    @media only screen and (max-width: 767px) {
      width: 135px;
      left: -45px;
    }
  }

  &::after {
    background-image: url("/awards/shape-10.png");
    width: 145px;
    height: 105px;
    bottom: -50px;
    right: 40px;

    @media only screen and (max-width: 767px) {
      width: 115px;
      height: 83px;
      bottom: -35px;
      right: 15px;
    }
  }

  ${SectionTitle} {
    h1 {
      font-size: 24px;
      color: #fff;

      @media only screen and (max-width: 767px) {
        font-size: 20px;
      }
    }
  }

  p {
    font-size: 16px;
    color: #fff;

    @media only screen and (max-width: 767px) {
      font-size: 14px;
    }
  }
`;

const SectionContainer = styled.div`
  position: relative;
  padding: 50px 0;
  z-index: 1;

  @media only screen and (max-width: 767px) {
    padding: 70px 0;
  }

  &::before {
    content: "";
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("/awards/shape-11.png");
    width: 210px;
    height: 80px;
    top: 0;
    right: 0;
    z-index: -1;

    @media only screen and (max-width: 767px) {
      width: 120px;
      height: 45px;
    }
  }
`;

const StyledButtonWithAnchor = styled(ButtonWithAnchor)`
  background: #fff;
  color: #055ce5;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;

  &::before {
    display: none;
  }
`;

const AboutCISection = () => {
  return (
    <SectionWrap>
      <SectionContainer>
        <Container>
          <Row className="justify-content-center">
            <Col md={10}>
              <SectionTitle>
                <StyledH1>About Charitable Impact</StyledH1>
              </SectionTitle>
              <StyledPara className="mb-5">
                Vancouver-based Charitable Impact is for anyone who wants to
                make a difference, no matter what causes they choose to support
                or how much they give. Just like a bank account for charitable
                giving, Charitable Impact’s donor-advised fund helps people
                simplify and organize their giving. Since being founded in 2011,
                more than $1.2 billion has been donated by the Charitable Impact
                community, with thousands of charities benefiting from the
                generosity of donors across Canada.
              </StyledPara>
              <StyledButtonWithAnchor
                text="Learn more"
                href="/"
                target="_blank"
              />
            </Col>
          </Row>
        </Container>
      </SectionContainer>
    </SectionWrap>
  );
};

export default AboutCISection;

import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';

const SectionWrap = styled.section`
  padding: 120px 0 100px;
  text-align: center;
  font-family: ProximaNova-Regular;
  position: relative;

  @media only screen and (max-width: 767px) {
    padding: 75px 0;
  }
`;

const TileImageLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 40%;
  height: 100%;

  img {
    position: absolute;
  }
`;

const TileImageLeft1 = styled.img`
  top: -25px;
  left: 0;

  @media only screen and (max-width: 1024px) {
    top: -10px;
    left: -80px;
  }

  @media only screen and (max-width: 767px) {
    width: 75px;
    left: -19px;
    top: 0;
  }
`;

const TileImageLeft2 = styled.img`
  top: 105px;
  left: 0;

  @media only screen and (max-width: 1024px) {
    top: 150px;
    left: -80px;
    width: 250px;
  }

  @media only screen and (max-width: 767px) {
    top: 35px;
    left: -48px;
    width: 120px;
  }
`;

const TileImageRight = styled(TileImageLeft)`
  right: 0;
  left: auto;
  overflow-x: clip;
`;

const TileImageRight1 = styled.img`
  top: -40px;
  right: 0;

  @media only screen and (max-width: 1024px) {
    right: -55px;
  }

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

const TileImageRight2 = styled.img`
  top: 60px;
  right: 0;

  @media only screen and (max-width: 1024px) {
    top: 90px;
    right: -65px;
  }

  @media only screen and (max-width: 767px) {
    width: 115px;
    right: -48px;
    bottom: -35px;
    top: auto;
  }
`;

const TileImageRight3 = styled.img`
  top: 180px;
  right: 0;

  @media only screen and (max-width: 1024px) {
    top: 238px;
    right: -65px;
  }

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

const TileImageRight4 = styled.img`
  bottom: 9px;
  right: 58px;

  @media only screen and (max-width: 1024px) {
    bottom: -20px;
    right: -7px;
  }

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

const TileImageRight5 = styled.img`
  bottom: -27px;
  right: 150px;

  @media only screen and (max-width: 1024px) {
    bottom: -56px;
    right: 85px;
  }

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

const BannerContent = styled.div``;

const BannerLogo = styled.div`
  img {
    @media only screen and (max-width: 1024px) {
      width: 267px;
    }

    @media only screen and (max-width: 767px) {
      width: 180px;
    }
  }
`;

const StyledPara = styled.p`
  font-size: 20px;
  color: #263238;
  max-width: 750px;
  margin: 25px auto 0;

  @media only screen and (max-width: 1024px) {
    max-width: 550px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 14px;
  }
`;

const BannerSection = () => {
  return (
    <SectionWrap>
      <TileImageLeft>
        <TileImageLeft1
          src='/awards/curve-line.png'
          alt='shape'
          className='animate__animated animate__fadeInRight animate__slow animate__delay-1s'
        />
        <TileImageLeft2
          src='/awards/shape-1.png'
          alt='shape'
          className='animate__animated animate__fadeInRight animate__slow animate__delay-1s'
        />
      </TileImageLeft>
      <BannerContent className='text-center'>
        <Container>
          <BannerLogo>
            <img src='/CI-awards.png' width='280' alt='CI award logo' />
          </BannerLogo>
          <StyledPara>
            Charitable Impact – Canada’s fastest-growing donor-advised fund,
            presents the winners of the 2023 Charitable Impact Awards. The
            Awards recognize Charitable Impact’s most active wealth advisor
            teams for their outstanding engagement with charitable giving on
            behalf of their clients.
          </StyledPara>
        </Container>
      </BannerContent>
      <TileImageRight>
        <TileImageRight1
          src='/awards/shape-2.png'
          alt='Shape'
          className='animate__animated animate__fadeInRight animate__slow animate__delay-1s'
        />
        <TileImageRight2
          src='/awards/shape-3.png'
          alt='Shape'
          className='animate__animated animate__fadeInRight animate__slow animate__delay-1s'
        />
        <TileImageRight3
          src='/awards/dotbg.png'
          alt='Shape'
          className='animate__animated animate__fadeInRight animate__slow animate__delay-1s'
        />
        <TileImageRight4
          src='/awards/violet-rectangle.png'
          alt='Shape'
          className='animate__animated animate__fadeInRight animate__slow animate__delay-1s'
        />
        <TileImageRight5
          src='/awards/red-square.png'
          alt='Shape'
          className='animate__animated animate__fadeInRight animate__slow animate__delay-1s'
        />
      </TileImageRight>
    </SectionWrap>
  );
};

export default BannerSection;

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { StyledH1 } from "../AccountAgreement/AgreementTermsSection";

const SectionWrap = styled.section`
  padding: 80px 0;
`;

const ImageSection = styled.div``;

const ContentSection = styled.div`
  ${StyledH1} {
    margin-bottom: 40px;
  }
`;

const StyledUl = styled.ul`
  list-style: none;
  padding: 0;
`;

const ListItem = styled.li`
  display: flex;
  margin-bottom: 15px;

  &:not(:first-child) {
    p,
    a {
      color: #263238;
    }
  }
`;

const ListIcon = styled.span``;

const ListText = styled.span`
  margin-left: 15px;

  h3 {
    font-size: 16px;
    color: #263238;
    margin: 0;
    font-weight: 600;
    font-family: "ProximaNova-Semibold";
  }

  p,
  a {
    font-size: 16px;
    color: #8b8b8b;
    margin: 0;
  }

  @media only screen and (max-width: 767px) {
    h3,p,a{
      font-size: 14px;
    }
  }
`;

const MediaInquirySection = () => {
  return (
    <SectionWrap>
      <Container>
        <Row className="align-items-center justify-content-between flex-column-reverse flex-md-row">
          <Col md={6}>
            <ImageSection className="mt-5 mt-md-0">
              <img src="/awards/inquiry-person.png" alt="Luisa" />
            </ImageSection>
          </Col>
          <Col md={6} lg={5}>
            <ContentSection className="pe-3">
              <StyledH1>
                For interview requests and media inquiries, please contact:
              </StyledH1>
              <StyledUl>
                <ListItem>
                  <ListIcon>
                    <img
                      src="/awards/icon/icon-user.png"
                      width="16px"
                      alt="user icon"
                    />
                  </ListIcon>
                  <ListText>
                    <h3>Luisa Velez</h3>
                    <p>Director of Corporate Communications</p>
                  </ListText>
                </ListItem>
                <ListItem>
                  <ListIcon>
                    <img
                      src="/awards/icon/icon-phone.png"
                      width="16px"
                      alt="phone icon"
                    />
                  </ListIcon>
                  <ListText>
                    <p>778-302-2978</p>
                  </ListText>
                </ListItem>
                <ListItem>
                  <ListIcon>
                    <img
                      src="/awards/icon/icon-mail.png"
                      width="16px"
                      alt="mail icon"
                    />
                  </ListIcon>
                  <ListText>
                    <a href="mailto:media@charitableimpact.com">
                      media@charitableimpact.com
                    </a>
                  </ListText>
                </ListItem>
              </StyledUl>
            </ContentSection>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default MediaInquirySection;

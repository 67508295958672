import React from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { StyledH1 } from "../AccountAgreement/AgreementTermsSection";
import { SectionTitle } from "./AwardWinnersSection";

const SectionWrap = styled.section`
  padding: 80px 0;

  ${SectionTitle} {
    h1 {
      @media only screen and (max-width: 767px) {
        font-size: 20px;
      }
    }
  }
`;

const FAQDropdown = styled.div`
  .accordion {
    .accordion-item {
      padding: 15px 0;
      border-width: 1px 0;
      border-radius: 0;
      border-color: #d9d9d9;
    }

    .accordion-button,
    .accordion-button:not(.collapsed) {
      color: #263238;
      background: transparent;
      box-shadow: none;
      font-family: "ProximaNova-Regular";
      font-weight: 600;

      @media only screen and (max-width: 767px) {
        font-size: 14px;
      }

      &::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      }
    }

    .accordion-body {
      padding: 0.5rem 1.25rem;
      font-size: 16px;
      font-family: "ProximaNova-Regular";
      color: #7f7f7f;

      @media only screen and (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
`;

const FAQSection = () => {
  return (
    <SectionWrap>
      <Container>
        <SectionTitle>
          <StyledH1 className="mb-5">Frequently asked questions</StyledH1>
        </SectionTitle>
        <Row>
          <Col md={12}>
            <FAQDropdown>
              <Accordion defaultActiveKey="">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    What are the Charitable Impact Awards?
                  </Accordion.Header>
                  <Accordion.Body>
                    The Charitable Impact Awards recognize the most active
                    wealth management teams for their outstanding engagement
                    with charitable giving on behalf of their clients.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    Does participation in the Awards involve any costs?
                  </Accordion.Header>
                  <Accordion.Body>
                    No – it’s completely free to enter the Charitable Impact
                    Awards.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    Can one wealth management firm or advisor team win multiple
                    awards?
                  </Accordion.Header>
                  <Accordion.Body>
                    There are no limits to how many awards can be won by an
                    individual or organization as long as they meet the criteria
                    for the category.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    What are the criteria used for selecting the winners?
                  </Accordion.Header>
                  <Accordion.Body>
                    Charitable Impact tracks the activity and performance of
                    wealth management teams by measuring indicators such as
                    number of donations, amount donated, number of accounts and
                    overall engagement in philanthropy.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    How do I participate as a financial advisor?
                  </Accordion.Header>
                  <Accordion.Body>
                    The awards are open to all wealth management firms and
                    advisor firms who participate in the Charitable Investment
                    Program.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    What is the Charitable Investment Program?
                  </Accordion.Header>
                  <Accordion.Body>
                    The Charitable Investment Program helps financial
                    professionals across Canada to integrate charitable
                    donations and charitable asset management solutions into
                    their service offering so they can help their clients with
                    their giving, regardless of which charities their clients
                    choose to support, how much money they want to give away, or
                    how much experience they have with giving.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    When are the next awards being presented?
                  </Accordion.Header>
                  <Accordion.Body>
                    The next awards will be presented in Summer 2024
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </FAQDropdown>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default FAQSection;

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Card, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import styled from "styled-components";
import { StyledH2, StyledH3 } from "../../styles/globalStyles";
import {
  StyledH1,
  StyledPara,
} from "../AccountAgreement/AgreementTermsSection";

import "swiper/css";
import AdvisorsQuotesSection from "./AdvisorsQuotesSection";
import { data } from "../../utils/awardsData";
import {
  AuthorImage,
  AuthorName,
  QuoteAuthor,
  QuotePara,
} from "./QuoteSection";

const SectionWrap = styled.section`
  padding: 80px 0 40px;
  overflow-x: clip;

  ${StyledH2} {
    @media only screen and (max-width: 767px) {
      font-size: 14px;
    }
  }

  ${StyledPara} {
    @media only screen and (max-width: 767px) {
      font-size: 13px;
    }
  }
`;

export const SectionTitle = styled.div`
  h1 {
    font-size: 28px;
    text-align: center;

    @media only screen and (max-width: 767px) {
      font-size: 24px;
      padding: 0 30px 10px;
    }
  }
`;

const TeamOfTheYear = styled.div``;

const SubHeading = styled.h4`
  color: #263238;

  @media only screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

const TeamOfTheYearCard = styled.div`
  margin-top: 40px;
  position: relative;

  .card-body {
    padding: 40px;

    @media only screen and (min-width: 1200px) {
      padding: 25px 50px;
    }

    @media only screen and (max-width: 767px) {
      padding: 20px;
    }
  }
`;

const CardImage = styled.div`
  width: 186px;

  @media only screen and (max-width: 991px) {
    width: 150px;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;

const CardContent = styled.div`
  h2 {
    font-size: 20px;
    font-family: ProximaNova-Medium;
    margin: 0 0 5px;
    line-height: 24px;

    @media only screen and (max-width: 767px) {
      font-size: 20px;
      margin: 0 0 5px;
    }
  }

  h3 {
    font-size: 16px;
    font-family: ProximaNova-Medium;
    line-height: 22px;
    margin: 0 0 20px;

    @media only screen and (max-width: 767px) {
      font-size: 14px;
      margin: 0 0 5px;
    }
  }

  p {
    font-size: 16px;

    @media only screen and (max-width: 767px) {
      font-size: 14px;
    }
  }
`;

const WinnerImage = styled.div`
  border-radius: 5px;
  overflow: hidden;
`;

const WinnerPartners = styled.div``;

const TopAdvisorsTeam = styled.div`
  margin-top: 60px;
  position: relative;
`;

const TopAdvisorsTeamDetails = styled.div`
  margin-top: 40px;
`;

const TeamGrid = styled.div`
  .swiper-slide {
    @media only screen and (min-width: 768px) {
      width: 260px;
    }

    @media only screen and (max-width: 767px) {
      width: 210px;
    }
  }
`;

const TeamCol = styled.div``;

const TeamImage = styled.div`
  margin-bottom: 25px;

  img {
    border-radius: 6px;
  }
`;

const TeamText = styled.p`
  color: #263238;
  min-height: 80px;
  font-size: 14px;
  font-family: "ProximaNova-Regular";

  @media only screen and (max-width: 991px) {
    font-size: 14px;
  }
`;

const TeamCompany = styled.div``;

const TabWrap = styled.div`
  .nav-tabs {
    border: 0;
    gap: 8px;
    justify-content: center;

    .nav-item {
      .nav-link {
        border: 0;
        background: #f6f6f6;
        border-radius: 40px;
        color: #1f1f1f;
        font-size: 17px;
        font-weight: 600;
        padding: 10px 16px;
        line-height: 25px;
        cursor: pointer;
        display: flex;

        &:hover,
        &.active {
          background: #009ea2;
          color: #fff;
        }
      }
    }
  }

  .tab-content {
    margin-top: 72px;
  }
`;

const SwiperContainer = ({ children }) => {
  return (
    <Swiper
      pagination={{
        clickable: true,
      }}
      breakpoints={{
        320: {
          slidesPerView: "auto",
          spaceBetween: 20,
        },
        768: {
          slidesPerView: "auto",
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 20,
        },
      }}
      className="mySwiper"
    >
      {children}
    </Swiper>
  );
};

const AwardWinnersSection = () => {
  return (
    <SectionWrap>
      <TabWrap>
        {/* Tab Container */}
        <Tabs defaultActiveKey="2023" id="award-winners-tabs" className="mb-3">
          <Tab eventKey="2023" title="2023">
            <Container>
              <Row>
                <Col md={12}>
                  <SectionTitle>
                    <StyledH1>{data[2023].heading}</StyledH1>
                  </SectionTitle>
                </Col>

                <Col md={12}>
                  <TeamOfTheYear className="mt-md-5">
                    <SubHeading className="text-center">
                      {data[2023].subheading}
                    </SubHeading>
                    <TeamOfTheYearCard>
                      <Card>
                        <Card.Body>
                          <Row className="align-items-sm-center">
                            <Col xs={4} md={4} lg={3}>
                              <CardImage>
                                <WinnerImage>
                                  <img
                                    src={data[2023].block.image}
                                    alt={data[2023].block.title}
                                  />
                                </WinnerImage>
                                <WinnerPartners>
                                  <img
                                    src={data[2023].block.logo}
                                    alt={data[2023].block.title}
                                    width={147}
                                  />
                                </WinnerPartners>
                              </CardImage>
                            </Col>
                            <Col xs={8} md={8} lg={9}>
                              <CardContent>
                                <StyledH2>{data[2023].block.title}</StyledH2>
                                <StyledH3>{data[2023].block.subTitle}</StyledH3>
                                <StyledPara className="m-0">
                                  {data[2023].block.description}
                                </StyledPara>
                              </CardContent>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </TeamOfTheYearCard>
                  </TeamOfTheYear>
                </Col>

                {/* Advisor Team Section 2023 */}
                <Col md={12}>
                  <TopAdvisorsTeam>
                    <SubHeading className="text-center">
                      Top 5 Standout Wealth Advisor Teams
                    </SubHeading>
                    <TopAdvisorsTeamDetails>
                      <TeamGrid>
                        <SwiperContainer>
                          {data[2023].advisorTeam.map(
                            ({ title, description, thumbnail, logo }) => {
                              return (
                                <SwiperSlide key={title}>
                                  <TeamCol>
                                    <TeamImage>
                                      <img src={thumbnail} alt={title} />
                                    </TeamImage>
                                    <TeamText>
                                      {title} <br /> {description}
                                    </TeamText>
                                    <TeamCompany>
                                      <img src={logo} alt={title} width={169} />
                                    </TeamCompany>
                                  </TeamCol>
                                </SwiperSlide>
                              );
                            }
                          )}
                        </SwiperContainer>
                      </TeamGrid>
                    </TopAdvisorsTeamDetails>
                  </TopAdvisorsTeam>
                </Col>
              </Row>
            </Container>

            {/* Quotes Section 2023 */}
            <Row>
              <Col md={12} className="mt-5 pt-5">
                <AdvisorsQuotesSection>
                  {data[2023].quotes.map(({ quote, image, name, title }) => {
                    return (
                      <SwiperSlide key={title}>
                        <QuotePara>{quote}</QuotePara>
                        <QuoteAuthor className="d-flex align-items-center justify-content-end mt-5">
                          <AuthorImage>
                            <img src={image} width="55" alt={name} />
                          </AuthorImage>
                          <AuthorName className="ps-3">
                            <h3>{name}</h3>
                            <p>{title}</p>
                          </AuthorName>
                        </QuoteAuthor>
                      </SwiperSlide>
                    );
                  })}
                </AdvisorsQuotesSection>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="2022" title="2022">
            <Container>
              <Row>
                <Col md={12}>
                  <SectionTitle>
                    <StyledH1>{data[2022].heading}</StyledH1>
                  </SectionTitle>
                </Col>

                <Col md={12}>
                  <TeamOfTheYear className="mt-md-5">
                    <SubHeading className="text-center">
                      {data[2022].subheading}
                    </SubHeading>
                    <TeamOfTheYearCard>
                      <Card>
                        <Card.Body>
                          <Row className="align-items-sm-center">
                            <Col xs={4} md={4} lg={3}>
                              <CardImage>
                                <WinnerImage>
                                  <img
                                    src={data[2022].block.image}
                                    alt={data[2022].block.title}
                                  />
                                </WinnerImage>
                                <WinnerPartners>
                                  <img
                                    src={data[2022].block.logo}
                                    alt={data[2022].block.title}
                                  />
                                </WinnerPartners>
                              </CardImage>
                            </Col>
                            <Col xs={8} md={8} lg={9}>
                              <CardContent>
                                <StyledH2>{data[2022].block.title}</StyledH2>
                                <StyledPara className="m-0">
                                  {data[2022].block.description}
                                </StyledPara>
                              </CardContent>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </TeamOfTheYearCard>
                  </TeamOfTheYear>
                </Col>

                {/* Advisor Team Section 2022 */}
                <Col md={12}>
                  <TopAdvisorsTeam>
                    <SubHeading className="text-center">
                      Top 5 Standout Wealth Advisor Teams
                    </SubHeading>
                    <TopAdvisorsTeamDetails>
                      <TeamGrid>
                        <SwiperContainer>
                          {data[2022].advisorTeam.map(
                            ({ title, thumbnail, logo }) => {
                              return (
                                <SwiperSlide key={title}>
                                  <TeamCol>
                                    <TeamImage>
                                      <img src={thumbnail} alt="" />
                                    </TeamImage>
                                    <TeamText>{title}</TeamText>
                                    {logo && (
                                      <TeamCompany>
                                        <img
                                          src={logo}
                                          alt={title}
                                          width={169}
                                          style={{
                                            objectFit: "contain",
                                            height: "50px",
                                          }}
                                        />
                                      </TeamCompany>
                                    )}
                                  </TeamCol>
                                </SwiperSlide>
                              );
                            }
                          )}
                        </SwiperContainer>
                      </TeamGrid>
                    </TopAdvisorsTeamDetails>
                  </TopAdvisorsTeam>
                </Col>
              </Row>
            </Container>

            {/* Quotes Section 2022 */}
            <Row>
              <Col md={12} className="mt-5 pt-5">
                <AdvisorsQuotesSection>
                  {data[2022].quotes.map(({ quote, image, name, title }) => {
                    return (
                      <SwiperSlide key={title}>
                        <QuotePara>{quote}</QuotePara>
                        <QuoteAuthor className="d-flex align-items-center justify-content-end mt-5">
                          <AuthorImage>
                            <img src={image} width="55" alt={name} />
                          </AuthorImage>
                          <AuthorName className="ps-3">
                            <h3>{name}</h3>
                            <p>{title}</p>
                          </AuthorName>
                        </QuoteAuthor>
                      </SwiperSlide>
                    );
                  })}
                </AdvisorsQuotesSection>
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </TabWrap>
    </SectionWrap>
  );
};

export default AwardWinnersSection;

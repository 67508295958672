import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import {
  StyledH1,
  StyledPara,
} from "../AccountAgreement/AgreementTermsSection";

const SectionWrap = styled.section`
  padding: 80px 0;

  ${StyledH1} {
    font-size: 24px;

    @media only screen and (max-width: 767px) {
      font-size: 20px;
    }
  }

  ${StyledPara} {
    font-size: 16px;

    @media only screen and (max-width: 767px) {
      font-size: 14px;
    }
  }
`;

const ContentSection = styled.div``;

const ImageSection = styled.div``;

const InvestmentPrograms = () => {
  return (
    <SectionWrap>
      <Container>
        <Row className="align-items-center justify-content-between">
          <Col md={7}>
            <ContentSection>
              <StyledH1 className="mb-4">
                About Charitable Investment Programs
              </StyledH1>
              <StyledPara className="mb-5 mb-md-0">
                The Charitable Investment Program helps financial professionals
                across Canada to integrate charitable donations and charitable
                asset management solutions into their service offering so they
                can help their clients with their giving, regardless of which
                charities their clients choose to support, how much money they
                want to give away, or how much experience they have with giving.
              </StyledPara>
            </ContentSection>
          </Col>
          <Col md={5} lg={4}>
            <ImageSection>
              <img src="/awards/chimp-bank.png" alt="Chimp Bank" />
            </ImageSection>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default InvestmentPrograms;

import React from 'react';
import AboutCISection from '../components/Awards/AboutCISection';
import AwardWinnersSection from '../components/Awards/AwardWinnersSection';
import BannerSection from '../components/Awards/BannerSection';
import FAQSection from '../components/Awards/FAQSection';
import InvestmentPrograms from '../components/Awards/InvestmentPrograms';
import MediaInquirySection from '../components/Awards/MediaInquirySection';
import QuoteSection from '../components/Awards/QuoteSection';
import Layout from '../components/Layout/Layout';

const pageInfo = {
  path: '/awards',
  title: 'Awards',
  description:
    ' Canada’s fastest-growing donor-advised fund, presents the winners of the first ever Charitable Impact Awards.',
};

const Awards = () => {
  return (
    <Layout pageInfo={pageInfo}>
      <BannerSection />
      <QuoteSection />
      <AwardWinnersSection />
      <FAQSection />
      <AboutCISection />
      <InvestmentPrograms />
      <MediaInquirySection />
    </Layout>
  );
};

export default Awards;

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Swiper } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import { AuthorName, QuotePara } from './QuoteSection';
import { SectionTitle } from './AwardWinnersSection';
import { StyledH1 } from '../AccountAgreement/AgreementTermsSection';

const SectionWrap = styled.section`
  padding: 80px 0;
  font-family: 'ProximaNova-Regular';
  background: #f5f8fc;
  position: relative;

  @media only screen and (max-width: 767px) {
    padding: 60px 0;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    height: 100px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &::before {
    background-image: url('/awards/shape-7.png');
    top: -20px;
    left: 0;
    width: 125px;

    @media only screen and (max-width: 767px) {
      top: -35px;
      left: -20px;
    }
  }

  &::after {
    background-image: url('/awards/shape-8.png');
    bottom: -8px;
    right: 0;
    width: 115px;
  }

  .section-title {
    h1 {
      @media only screen and (max-width: 767px) {
        font-size: 20px;
      }
    }
  }

  .swiper-slide {
    padding: 40px 45px 0;

    @media only screen and (max-width: 767px) {
      padding: 20px 45px;
    }

    ${QuotePara} {
      font-size: 16px;

      @media only screen and (max-width: 767px) {
        font-size: 14px;
        text-align: center;
      }

      &::before {
        width: 20px;

        @media only screen and (max-width: 991px) {
          left: -35px;
        }
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0px 2px 7px 0px rgba(135, 135, 135, 0.3);
    transform: translateY(-50%);

    &::after {
      font-size: 14px;
      font-weight: 700;
      color: #4e4e4e;
    }

    &.swiper-button-disabled {
      opacity: 0;
    }
  }

  .swiper-button-next {
    right: 0;
  }

  .swiper-button-prev {
    left: 0;
  }
`;

const SwiperWrap = styled.div`
  position: relative;

  .swiper {
    position: static;

    ${AuthorName} {
      p {
        max-width: 190px;
      }
    }
  }
`;

const AdvisorsQuotesSection = ({ children, ...rest }) => {
  return (
    <SectionWrap {...rest}>
      <Container>
        <SectionTitle className='section-title'>
          <StyledH1>Quotes from advisors</StyledH1>
        </SectionTitle>
        <SwiperWrap>
          <Row className='justify-content-center'>
            <Col xs={12} md={10} lg={9}>
              <Swiper
                navigation={true}
                modules={[Navigation]}
                spaceBetween={50}
                slidesPerView={1}>
                {children}
              </Swiper>
            </Col>
          </Row>
        </SwiperWrap>
      </Container>
    </SectionWrap>
  );
};

export default AdvisorsQuotesSection;
